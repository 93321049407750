'use client';

import * as Sentry from '@sentry/nextjs';
import { Button } from '@/components/ui/button';

/**
 * @inheritDoc
 */
const Error = ({ error, reset }: { error: Error; reset: () => void }) => {
  Sentry.captureException(error);

  return (
    <div>
      <h2>Something went wrong!</h2>
      <Button onClick={() => reset()}>Try again</Button>
    </div>
  );
};

export default Error;
