import { Loader, Loader2, RefreshCw, RotateCw } from 'lucide-react';
import { cn } from '@/lib/utils';

const components = {
  load: Loader,
  wait: Loader2,
  reload: RotateCw,
  refresh: RefreshCw,
};

type TSpinnerProps = {
  spinner?: keyof typeof components;
  className?: string;
};

/**
 * Spinner component
 * @param props - The props for the spinner component
 * @param [props.spinner] - The type of spinner to render
 * @param [props.className] - The class name for the spinner component
 * @returns The spinner component
 */
export function Spinner({ spinner = 'load', className }: TSpinnerProps) {
  const Spinner = components[spinner];
  return <Spinner className={cn('animate-spin', className)} />;
}
